import { Player } from '@lottiefiles/react-lottie-player';
import Loading from '../animations/loading-dots.json'

export const LoadingScreen = () => {


    return (<div style={{display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        minHeight: '90vh'}}>

        <Player
            autoplay
            loop
            src={Loading}
            style={{ height: '300px', width: '300px' }}
        >
        </Player>

    </div>)


}
