import {useSelector} from "react-redux";
import {MatchedComponent} from "../components/Matched";

export const Matched = ({children}) => {

    const matched = useSelector(state => state.matched.isMatched)

    if (matched) {
        return <MatchedComponent/>
    } else {
        return children
    }

}
