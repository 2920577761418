import React from "react";
import "./Header.css";
import PersonIcon from "@mui/icons-material/Person";
import ForumIcon from "@mui/icons-material/Forum";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowBackIos";
import {Link, useParams} from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import {addDoc, collection, updateDoc, doc, getDoc} from "firebase/firestore";
import {db, auth} from "../firebase/firebase";
import {ArrowForward} from "@mui/icons-material";

function Header({ backButton, report, account }) {
  const navigate = useNavigate();

  const {chatId} = useParams()

  const reportUser = () => {
      Swal.fire({
          title: 'Do you want to report this user?',
          text: 'Reporting the user will automatically block them',
          input: 'text',
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: 'Report',
          denyButtonText: `Don't report`,
          inputPlaceholder: "Please describe the reason for reporting this user"
      }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {

              getDoc(doc(db, 'groups', chatId)).then((chat) => {
                  const personId = chat.data()['members'].filter((id1) => id1 !== auth.currentUser.uid)
                  addDoc(collection(db, `reports`), {
                      'groupReport': chatId,
                      'sentReportId': auth.currentUser.uid,
                      'reportedUID': personId[0],
                      'reason': result.value
                  }).then(() => {
                      updateDoc(doc(db, `groups/${chatId}`), {
                          'reported': true
                      }).then(() => {
                          Swal.fire('Reported!', '', 'success').then(() => {
                              navigate('/chat')
                          })

                      })
                  })
              })

          }
      })
  }

  return (
    <div className="header_guur">
        <div style={{flex: 1}}>
            {backButton ? (
                <IconButton onClick={() => navigate(backButton)} style={{height: '55px', width: '55px', marginLeft: '4vw'}}>
                    <ArrowBackIosIcon style={{marginLeft: '20px'}} className="header__icon_guur" fontSize="large" />
                </IconButton>
            ) : (account ? (<IconButton onClick={() => navigate('/account')} style={{height: '55px', width: '55px', marginLeft: '4vw'}}>
                    <ArrowBackIosIcon style={{marginLeft: '20px'}} className="header__icon_guur" fontSize="large" />
                </IconButton>) : ( <IconButton onClick={() => navigate('/account')} style={{height: '55px', width: '55px', marginLeft: '4vw'}}>
                    <PersonIcon className="header__icon_guur" fontSize="large" />
                </IconButton>)

            )}
        </div>

        <div style={{flex: 3, alignSelf:'center'}}>

            <div onClick={() => navigate('/')} className={'p-3'}
                 style={{ fontFamily: 'Bonair',
                     fontSize: '35px', color: 'rgb(231 73 149)', margin: 'auto',
                     textAlign: 'center', cursor: 'pointer', width: 'fit-content'}}>
                Guursamo
            </div>

        </div>
        <div style={{flex: 1, textAlign: 'end'}}>

            {backButton ? (report ?
                (<span onClick={() => reportUser()} style={{textAlign: 'right', fontSize: '12px', cursor: 'pointer', marginRight: '10%'}}>
                Report user
            </span>) : (null)) : (account ? (null) : (<div style={{flex: 1, textAlign: 'end'}}>
                <Link to="/chat">
                    <IconButton style={{height: '55px', width: '55px', marginRight: '4vw'}}>
                        <ForumIcon className="header__icon_guur" fontSize="large" />
                    </IconButton>
                </Link>
            </div>))}
        </div>
    </div>
  );
}

export default Header;
