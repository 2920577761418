import './matched.css'
import {useDispatch, useSelector} from "react-redux";
import {setMatched} from "../redux/slices/matched";

export const MatchedComponent = () => {

    const dispatch = useDispatch()

    const profile = useSelector(state => state.matched.profile_img)

    const closeMatch = () => {
        dispatch(setMatched(false))
    }

    return <div className={'match-container'}>

        <img src={profile}
             className={'person-container'}/>

        <div className={'interact-match'}>
            <h1 style={{textAlign: 'center',
                fontSize: '50px', color: 'white',fontFamily: 'bonair', webkitTextStroke: '1px #e94c97'}}>It's a match</h1>
            <button onClick={() => closeMatch()}
                    style={{fontSize: '20px', height: '40px'}}>
                Keep swiping</button>
        </div>

    </div>

}
