import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./slices/auth";
import dataReducer from './slices/data'
import matchedReducer from './slices/matched'
import profileReducer from './slices/profile'

export default configureStore({
    reducer: {
        auth: authReducer,
        data: dataReducer,
        matched: matchedReducer,
        profile: profileReducer
    }
})
