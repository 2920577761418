import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { LoadingScreen } from "./loadingScreen";

export const FlowControl = ({ children }) => {

    const data = useSelector((state) => state.data.user_data)

    const [path, setPath] = useState(data)

    const HandleFlow = () => {

        useEffect(() => {

            if (data === 'loading') {
                setPath('loading')
                return
            }

            if (data === null || data === undefined || !('geohash' in data)) {
                setPath('location')
                return;
            }

            if (('isBan' in data)) {
                setPath('banned')
                return;
            }

            if (!('profile' in data)) {
                setPath('profile')
                return;
            }


            if (!('interests' in data)) {
                setPath('interests')
                return;
            }

            if (!('work' in data)) {
                setPath('work')
                return;
            }

            setPath('home')


        }, [data])


        if (path === 'banned') {
            return <div style={{ padding: '30px' }}>Account is Banned</div>
        }

        if (path === 'loading') {
            return <LoadingScreen />
        }

        if (path === 'location') {
            if (window.location.pathname !== '/location') {
                return <Navigate to={'/location'} />
            }
        }

        if (path === 'profile') {
            if (window.location.pathname !== '/profile') {
                return <Navigate to={'/profile'} />
            }
        }

        if (path === 'interests') {
            if (window.location.pathname !== '/interests') {
                return <Navigate to={'/interests'} />
            }
        }

        if (path === 'work') {
            if (window.location.pathname !== '/work') {
                return <Navigate to={'/work'} />
            }
        }

        // if (path === 'subscription') {
        //     if (window.location.pathname !== '/pay') {
        //         return <Navigate to={'/pay'} />
        //     }
        // }

        if (path === 'home') {
            if (window.location.pathname !== '/'
                && !(window.location.pathname.includes('/chat'))
                && !(window.location.pathname.includes('/account'))
                && !(window.location.pathname.includes('/profile/edit'))
                && !(window.location.pathname.includes('/interests/edit'))
                && !(window.location.pathname.includes('/location/edit'))
            ) {
                return <Navigate to={'/'} />
            }
        }

        return children

    }

    return HandleFlow()

}
