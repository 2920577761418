import {useEffect, useState} from "react";
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "../firebase/firebase";
import {setUser} from "../redux/slices/auth";
import {useDispatch} from "react-redux";
import {VerifyEmail} from "./verifyEmail";

export const AuthListener = ({children}) => {

    const dispatch = useDispatch()
    const [currentUser, setCurrentUser] = useState(null)

    useEffect(() => {
        const auth_listener = onAuthStateChanged(auth,
            (user) => {
                if (user) {
                    setCurrentUser(user)
                    dispatch(setUser(user))
                } else {
                    dispatch(setUser(null))
                }
            })
        return () => auth_listener()

    }, [])


    if (currentUser !== null) {

        if (!currentUser.emailVerified) {
            return <VerifyEmail/>
        }
    }

    return children

}
