import { createSlice } from '@reduxjs/toolkit'

export const dataSlice = createSlice({
    name: 'data',
    initialState: {
        user_data: 'loading',
    },
    reducers: {
        setData: (state, action) => {
            state.user_data = action.payload
        },

    },
})

export const { setData } = dataSlice.actions

export default dataSlice.reducer
