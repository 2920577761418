import './style.css'

export const AppHeader = () => {

    return <div className={'p-3'}
                style={{marginLeft: '5vw', fontFamily: 'Bonair',
                    fontSize: '35px', color: 'rgb(231 73 149)'}}>
                    Guursamo
            </div>

}
