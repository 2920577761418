import {useEffect, useState} from "react";
import {doc, setDoc} from "firebase/firestore";
import {auth, db} from "../../../firebase/firebase";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

export const Interests = ({edit}) => {

    const data = useSelector((state) => state.data.user_data)
    const [interests, setInterests] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const selectedInterests = (id) => {

        if (interests.includes(id)) {
            setInterests(interests.filter(item => item !== id))

        } else {
            setInterests(cate => [...cate, id])
        }

    }

    useEffect(() => {

        if (edit) {

            setInterests(data['interests'])

        }

    }, [])

    const interestData = ['None', 'Music', 'Reading', 'Writing', 'Photography', 'Walking', 'Adventures', 'Travelling',
        'Cooking', 'TV', 'Football', 'Games', 'Yoga', 'Health', 'Sports', 'Volunteering', 'Cinema', 'Pottery', 'Gambling',
    'Weight lifting', 'Ice Skating', 'Winter sports', 'Camping', 'Astronomy', 'Shopping', 'Dancing', 'singing', 'Sunbathing']

    const renderInterestItem = (variation) => {
        return interestData.map((item, index) => {
            if (index <= variation && index > variation - 3) {
                return <div className="mb-10 " style={{flex: 1}}>
                    <div className="form-check">
                        <input checked={interests.includes(interestData[index]) ? (true) : (false)}
                               onChange={(val) =>
                            selectedInterests(interestData[index])} className="form-check-input" type="checkbox" defaultValue id="hotels" />
                        <label className="form-check-label">{interestData[index]}</label>
                    </div>

                </div>
            }
        })

    }

    const renderInterests = (variation) => {
        if (variation % 3 === 0) {
            return <div id={`interest-${variation}`} style={{flexDirection: 'row', display: 'flex'}}>
                {renderInterestItem(variation)}
            </div>
        }
    }

    const uploadInterests = () => {

        if (interests.length === 0) {
            alert('You must at least provide 1 interest or hobby')
            return
        }

        setLoading(true)

        setDoc(doc(db, 'users', auth.currentUser.uid), {
            'interests': interests
        }, {merge: true}).then((resp) => {
            if (edit) {
                navigate('/account')
            } else {
                navigate('/work')
            }

        }).catch((err) => {
            alert(err.code)
            setLoading(false)
        })

    }

    return <div style={edit ? ({maxWidth: '800px', margin: 'auto', marginTop: '30px'}) : ({})}>
        <div className="py-5">
            <h5 style={{color: 'black'}}>Interests / Hobbies</h5>
            Select as many as you like to share
            <br/>
            <br/>
            <br/>
            <div className="rounded border p-10 " >
                <div className={'row'} style={{flexDirection: 'column'}}>
                        {renderInterests(3)}
                    {renderInterests(6)}
                    {renderInterests(9)}
                    {renderInterests(12)}
                    {renderInterests(15)}
                    {renderInterests(18)}
                    {renderInterests(21)}
                    {renderInterests(24)}
                    {renderInterests(27)}

                </div>
            </div>
        </div>

        {loading ? (<span className="indicator-progress" style={{display: 'block'}}>Please wait...
            <span className="spinner-border spinner-border-sm align-middle ms-2" /></span>) :
            ( <button onClick={() => uploadInterests()} style={{height: '45px'}} className={'btn btn-primary'}>
                {edit ? 'Save': 'Next'}
            </button>)}


    </div>

}
