import {useSelector} from "react-redux";
import './style.css'
import {auth} from "../../firebase/firebase";
import {useState} from "react";
import {LoadingScreen} from "../../utils/loadingScreen";
import {useNavigate} from "react-router-dom";

export const Account = () => {

    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const data = useSelector((state) => state.data.user_data)

    const manageSubscription = () => {
        setLoading(true)
        auth.currentUser.getIdToken().then((token) => {

            const body = {
                'Authorization': `Bearer ${token}`
            }
            const base_url = process.env.REACT_APP_BASE_URL

            fetch(`${base_url}/manage-subscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            }).then((response) => {
                if (response.status === 200) {
                    response.json().then((data) => {
                        window.location.replace(data['subscription_url'])
                    }).catch((error) => {
                        alert('Error connecting to payment checkout, please try again')
                        setLoading(false)
                    })
                } else {
                    alert('Failed to manage subscription, please try again')
                    setLoading(false)
                }

            })

        }).catch(() => {
            alert('Error connecting to payment checkout, please try again')
            setLoading(false)
        })

    }


    return <div style={{margin: 'auto', display: 'block', maxWidth: '25%', minWidth: '400px'}}>
        {loading ? <LoadingScreen/> : <div style={{padding: '50px'}}>
            <img style={{margin: 'auto', display: 'block', borderRadius: '50px',
                width: '100px', height: '100px', objectFit: 'cover'}} src={data['profile']['profileImg']}/>
            <h5 style={{textAlign: 'center', marginTop: '20px'}}>My Account</h5>

            <div onClick={() => navigate('/location/edit')}
                 className={'menu-option'} style={{backgroundColor: 'white', padding: '20px', borderRadius: '10px', marginTop: '30px'}}>
                <span>Change your location <span>
                    <img style={{width: '25px', height: '25px',
                        marginLeft: '90px'}}
                         src={'https://cdn-icons-png.flaticon.com/512/66/66831.png'}/></span></span>
            </div>

            <div onClick={() => manageSubscription()}
                 className={'menu-option'} style={{backgroundColor: 'white', padding: '20px', borderRadius: '10px', marginTop: '30px'}}>
                <span>Manage subscription <span>
                    <img style={{width: '25px', height: '25px',
                        marginLeft: '90px'}}
                         src={'https://cdn-icons-png.flaticon.com/512/66/66831.png'}/></span></span>
            </div>

            <div className={'menu-option'} onClick={() => navigate('/profile/edit')}
                 style={{backgroundColor: 'white', padding: '20px', borderRadius: '10px', marginTop: '30px'}}>
                <span>Edit profile <span>
                    <img style={{width: '25px', height: '25px',
                        marginLeft: '150px'}}
                         src={'https://cdn-icons-png.flaticon.com/512/66/66831.png'}/></span></span>
            </div>

            <div className={'menu-option'} onClick={() => navigate('/interests/edit')}
                 style={{backgroundColor: 'white', padding: '20px', borderRadius: '10px', marginTop: '30px'}}>
                <span>Edit Hobbies and Interests <span>
                    <img style={{width: '25px', height: '25px',
                        marginLeft: '60px'}}
                         src={'https://cdn-icons-png.flaticon.com/512/66/66831.png'}/></span></span>
            </div>

            <button onClick={() => auth.signOut()}>Log out</button>

        </div>}



    </div>

}
