import {auth} from "../firebase/firebase";
import {useState} from "react";
import {sendEmailVerification} from "@firebase/auth";


export const VerifyEmail = () => {

    const [sent, setSent] = useState(false)

    const sendEmail = () => {

        sendEmailVerification(auth.currentUser).then((resp) => {
            setSent(true)
        }).catch((err) => {
            alert('Failed to resend email, contact admin')
        })

    }

    return <div style={{position: 'absolute', left: '50%',  top: '50%', transform: 'translate(-50%, -50%)'}}>
        <div style={{display: 'flex', flexDirection: 'column', width: '20vw', minWidth: '250px'}}>
            <h4 style={{textAlign: 'center'}}>Please verify your email address</h4>
            <span style={{marginTop: '20px'}}>An email has been sent to <span style={{fontWeight: '600'}}>{auth.currentUser.email}</span>, please click the
                link in the email body to verify your account. (Don't forget to check spam)</span>
            {sent ? (<span>Email has been sent</span>): (<div style={{marginTop: '20px'}}>
                <span style={{textAlign: 'right'}}>Did not receive an email?</span>
                <button onClick={() => sendEmail()}>Re-send verification email</button>
            </div>)}

        </div>

    </div>

}
