import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth'
import { getFirestore } from "firebase/firestore";
import {getStorage} from 'firebase/storage'



const firebaseConfig = {
    apiKey: "AIzaSyCvMCHs7CQidgf3-30OyFbr-AN2GvW_nqw",
    authDomain: "guursamo-afda6.firebaseapp.com",
    projectId: "guursamo-afda6",
    storageBucket: "guursamo-afda6.appspot.com",
    messagingSenderId: "98257109515",
    appId: "1:98257109515:web:7592c17bdcc69d39963d0e",
    measurementId: "G-G6M01LCSCJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const db = getFirestore(app);
export const storage = getStorage(app)




