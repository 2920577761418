import React, { useEffect, useState } from 'react'

export default function PhoneModal(props) {
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    console.log(phoneNumber)
  }, [phoneNumber])


  return (
    <div style={{ position: 'fixed', height: '100vh', width: '100vw', zIndex: '10000', top: '0', left: '0', backgroundColor: "#00000020", display: "flex", justifyContent: 'center', alignItems: 'center', }}>
      <div style={{
        height: 'auto', width: '400px', backgroundColor: '#fff', padding: '20px', borderRadius: '10px'
      }}>

        <div>
          <label style={{ fontSize: '20px', fontWeight: 'bold' }}>Enter your phone number</label>
          <input type="text" placeholder="252615414470" style={{ height: '45px', width: '100%', border: '1px solid #00000020', borderRadius: '5px', padding: '10px' }} onChange={(e) => setPhoneNumber(e.target.value)} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
          <button style={{ height: '45px', width: '100px' }} onClick={() => props.setIsModalOpen(false)}>Cancel</button>
          <button style={{ height: '45px', width: '100px' }} onClick={() => props.submitPhone(phoneNumber)}>Confirm</button>
        </div>
      </div>
    </div>
  )
}
