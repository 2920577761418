
import {useSelector} from "react-redux";
import { Navigate } from 'react-router-dom';

export const ProtectedRoute = ({children}) => {

    const user = useSelector((state) => state.auth.current_user)

    if (user === 'loading') {
        return
    }

    if (user === null) {
        return <Navigate to="/login"  />
    }

    return children

}
