import React from "react";
import "./Chat.css";
import Avatar from "@mui/material/Avatar";
import { Link } from "react-router-dom";


const Chat = ({ name, message, profilePic, timestamp, id }) => {
  return (
    <Link to={`/chat/${id}`}>
      <div className="chat">
        <Avatar className="chat__image" src={profilePic} />
        <div className="chat__details" style={{marginTop: '4px'}}>
          <h2 style={{width: 'max-content', marginTop: '7px'}}>{name}</h2>
          <p style={{}} className={'chatMessage'}>{message}</p>
        </div>
        <p style={{display: 'contents', textAlign: 'right',
          marginRight: '10px', color: 'gray'}} className="chat__timestamp">{timestamp}</p>
      </div>
    </Link>
  );
};

export default Chat;
