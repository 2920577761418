import React, { useEffect, useState } from "react";
import "./Chats.css";
import Chat from "./Chat"
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { db, auth } from "../../firebase/firebase";
import { LoadingScreen } from "../../utils/loadingScreen";
import ReactTimeAgo from 'react-time-ago'

const Chats = () => {

    //  Query all groups i am in

    const [chatData, setChatData] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {

        const groupRef = collection(db, "groups")
        const q = query(groupRef,
            where('members', 'array-contains', auth.currentUser.uid),
            orderBy('recentMessage.sentAt', 'desc'))

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            setChatData([])
            if (!querySnapshot.empty) {
                querySnapshot.forEach((docData) => {
                    if (!('reported' in docData.data())) {
                        setChatData(prevState => [...prevState, docData.data()])
                    }
                });
                setLoading(false)
            } else {
                setLoading(false)
            }
        })

        return () => {
            setChatData([])
            unsubscribe()
        }

    }, [])

    const renderChats = () => {

        if (chatData.length === 0) {
            return <div>
                <span style={{
                    margin: 'auto', display: 'block',
                    textAlign: 'center', fontSize: '18px', marginTop: '40vh'
                }}>
                    No messages, start swiping!
                </span>

            </div>
        }

        return chatData.map((chat) => {

            const personId = chat['members'].filter((id1) => id1 !== auth.currentUser.uid)

            return <Chat
                id={chat['id']}
                name={chat[personId[0]]['profile']['display_name']}
                message={chat['recentMessage']['message']}
                timestamp={<ReactTimeAgo date={new Date(chat['recentMessage']['sentAt'] * 1000)} />}
                profilePic={chat[personId[0]]['profile']['profileImg']}
            />
        })
    }

    return <div className="chats" style={{ overflow: 'scroll' }}>
        <div style={{ marginTop: '20px' }} />
        {loading ? (<LoadingScreen />) : (renderChats())}
    </div>;
};

export default Chats;
