import { createSlice } from '@reduxjs/toolkit'

export const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        isShown: false,
        person: null
    },
    reducers: {
        setShown: (state, action) => {
            state.isShown = action.payload
        },
        setPerson: (state, action) => {
            state.person = action.payload
        }

    },
})

export const { setShown, setPerson } = profileSlice.actions

export default profileSlice.reducer
