import { createSlice } from '@reduxjs/toolkit'

export const matchSlice = createSlice({
    name: 'matched',
    initialState: {
        isMatched: false,
        profile_img: null
    },
    reducers: {
        setMatched: (state, action) => {
            state.isMatched = action.payload
        },
        setProfile: (state, action) => {
            state.profile_img = action.payload
        }

    },
})

export const { setMatched, setProfile } = matchSlice.actions

export default matchSlice.reducer
