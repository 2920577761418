import {useDispatch, useSelector} from "react-redux";
import { doc, onSnapshot } from "firebase/firestore";
import {db, auth} from "../firebase/firebase";
import {useEffect} from "react";
import {setData} from "../redux/slices/data";

export const DataListener = ({children}) => {

    const user = useSelector((state) => state.auth.current_user)
    const dispatch = useDispatch()

    useEffect(() => {
        if (user !== null && user !== 'loading') {
            const setUserListener = onSnapshot(doc(db, 'users', auth.currentUser.uid),
                (doc) => {
                if (doc.exists) {
                    dispatch(setData(doc.data()))
                } else {
                    dispatch(setData(null))
                }
            });
            return () => {
                setUserListener()
                dispatch(setData(null))
            }
        }

    }, [user])


    return children


}
