import React, {useEffect, useState} from "react";
import {
    doc,
    onSnapshot,
    collection,
    getDoc,
    query,
    where,
    orderBy,
    startAt,
    endAt,
    limit,
    getDocs
} from "firebase/firestore";
import {auth, db} from "../firebase/firebase";
import profile, {setPerson, setShown} from "../redux/slices/profile";
import {useDispatch, useSelector} from "react-redux";
import {ProfileComponent} from "../components/profile";

export const AdminPage = () => {

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [logged, setLogged] = useState(false)
    const personShown = useSelector(state => state.profile.isShown)
    const dispatch = useDispatch()
    const base_url = process.env.REACT_APP_BASE_URL

    const [tab, setTab] = useState(0)

    const [reports, setReports] = useState([])
    const [banned, setBanned] = useState([])

    useEffect(() => {

        const unsub = onSnapshot(collection(db, "reports"), (snap) => {
            setReports([])
            if (snap) {
                snap.forEach((document) => {
                    const docData = document.data()
                    getDoc(doc(db, 'users', docData['reportedUID'])).then((groupData) => {
                        const user = groupData.data()
                        user['reason'] = docData['reason']
                        user['report_id'] = document.id
                        setReports(prevState => [...prevState, user])
                    })

                })
            }
        });

        return () => {
            unsub()
        }

    },[])


    useEffect(() => {
        const userRef = collection(db, "users")
        const q = query(userRef,
            where('isBan', '==', true))

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            setBanned([])

            if (!querySnapshot.empty) {
                querySnapshot.forEach((docData) => {
                    setBanned(prevState => [...prevState, docData.data()])
                    })
                }
            })

        return () => {
            unsubscribe()
        }

    }, [])

    const Login = () => {

        if (username.toLowerCase() === 'guursamo' && password.toLowerCase() === '!guursamo330') {
            setLogged(true)
        } else {
            alert('Password or username incorrect')
        }

    }

    const renderBanned = () => {

        if (banned.length === 0) {
            return <div style={{padding: '20px'}}>No Banned Users</div>
        } else {
            return banned.map((ban, index) => {
                return <tr className={index % 2 === 0 ? "even" : 'odd'}>

                    <td className="d-flex align-items-center">
                        {/*begin:: Avatar */}
                        <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">

                            <div className="symbol-label">
                                <img src={ban['profile']['profileImg']} className="w-100"/>
                            </div>

                        </div>
                        {/*end::Avatar*/}
                        {/*begin::User details*/}
                        <div className="d-flex flex-column">
                            <span
                                className="text-gray-800 text-hover-primary mb-1">{ban['profile']['display_name']}</span>
                            <button onClick={() => showProfile(ban)} style={{height: '25px', fontSize: '11px'}}>View
                                profile
                            </button>
                        </div>
                        {/*begin::User details*/}
                    </td>

                    <td/>

                    <td className="text-end">
                        <button onClick={() => unbanUser(ban)} className="btn btn-light btn-active-light-primary btn-sm"
                                data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                            {/*begin::Svg Icon | path: icons/duotune/arrows/arr072.svg*/}

                            {/*end::Svg Icon*/}UnBan
                        </button>

                    </td>
                    {/*end::Action=*/}
                </tr>
            })
        }

    }

    const renderReports = () => {

        if (reports.length === 0) {
            return <div style={{padding: '20px'}}>No Reports</div>
        } else {
            return reports.map((report, index) => {

                return <tr className={index % 2 === 0 ? "even" : 'odd'}>
                    {/*begin::Checkbox*/}

                    {/*end::Checkbox*/}
                    {/*begin::User=*/}
                    <td className="d-flex align-items-center">
                        {/*begin:: Avatar */}
                        <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">

                            <div className="symbol-label">
                                <img src={report['profile']['profileImg']} className="w-100" />
                            </div>

                        </div>
                        {/*end::Avatar*/}
                        {/*begin::User details*/}
                        <div className="d-flex flex-column">
                            <span className="text-gray-800 text-hover-primary mb-1">{report['profile']['display_name']}</span>
                            <button onClick={() => showProfile(report)} style={{height: '25px', fontSize: '11px'}}>View profile</button>
                        </div>
                        {/*begin::User details*/}
                    </td>

                    <td >{report['reason']}</td>

                    <td className="text-end">
                        <button onClick={() => banUser(report)} className="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                            {/*begin::Svg Icon | path: icons/duotune/arrows/arr072.svg*/}

                            {/*end::Svg Icon*/}Ban</button>

                    </td>
                    {/*end::Action=*/}
                </tr>

            })

        }


    }

    const showProfile = (person) => {

        dispatch(setPerson(person))
        dispatch(setShown(true))
    }

    const unbanUser = (person) => {

        fetch(`${base_url}/unban-user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'auth': 'h!!@@//;QUIN',
                'profile': person.profile
            })

        }).then((response) => {

            if (response.status !== 200) {
                alert('Failed to unban user, please try again')
            }
        })

    }

    const banUser = (person) => {

        fetch(`${base_url}/ban-user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'auth': 'h!!@@//;QUIN',
                'report_id': person['report_id'],
                'profile': person.profile
            })

        }).then((response) => {

            if (response.status !== 200) {
                alert('Failed to ban user, please try again')
            }
        })

    }

    if (personShown) {
        return <ProfileComponent/>
    } else {
        return <div>

            {logged ? (<div>
                <div className="card mb-5 mb-xl-10">
                    <div className="card-body pt-9 pb-0">
                        <div className="d-flex flex-wrap flex-sm-nowrap mb-3">

                            <div className="flex-grow-1">
                                {/*begin::Title*/}
                                <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                    {/*begin::User*/}
                                    <div className="d-flex flex-column">
                                        {/*begin::Name*/}
                                        <div className="d-flex align-items-center mb-2">
                                            <a href="#" className="text-gray-900 text-hover-primary fs-2 fw-bold me-1">Admin page</a>
                                            <a href="#">
                                                {/*begin::Svg Icon | path: icons/duotune/general/gen026.svg*/}
                                                <span className="svg-icon svg-icon-1 svg-icon-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                    <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="currentColor" />
                    <path d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white" />
                  </svg>
                </span>
                                                {/*end::Svg Icon*/}
                                            </a>

                                        </div>

                                    </div>

                                </div>

                            </div>
                            {/*end::Info*/}
                        </div>
                        {/*end::Details*/}
                        {/*begin::Navs*/}
                        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                            {/*begin::Nav item*/}
                            <li className="nav-item mt-2">
                                <a className={tab === 0 ? ('active nav-link text-active-primary ms-0 me-10 py-5') : ("nav-link text-active-primary ms-0 me-10 py-5")} onClick={() => setTab(0)}>Reports</a>
                            </li>
                            {/*end::Nav item*/}
                            {/*begin::Nav item*/}
                            <li className="nav-item mt-2">
                                <a className={tab === 1 ? ('active nav-link text-active-primary ms-0 me-10 py-5') : ("nav-link text-active-primary ms-0 me-10 py-5")} onClick={() => setTab(1)}>Banned Users</a>
                            </li>
                            {/*end::Nav item*/}
                            {/*begin::Nav item*/}

                            {/*end::Nav item*/}
                        </ul>
                        {/*begin::Navs*/}
                    </div>
                </div>


                <div id="kt_app_content" className="app-content flex-column-fluid">
                    {/*begin::Content container*/}
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        {/*begin::Card*/}
                        <div className="card">
                            {/*begin::Card header*/}

                            {/*end::Card header*/}
                            {/*begin::Card body*/}
                            {tab === 0 ? (<div className="card-body py-4">
                                {/*begin::Table*/}
                                <div id="kt_table_users_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <div className="table-responsive"><table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_table_users">
                                        {/*begin::Table head*/}
                                        <thead>
                                        {/*begin::Table row*/}
                                        <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                                            <th className="min-w-125px sorting" tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1} aria-label="User: activate to sort column ascending" style={{width: '270.336px'}}>User</th>
                                            <th className="min-w-125px sorting" tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1}  style={{width: '70%'}}>Reason</th>
                                            <th className="text-end min-w-100px sorting_disabled" rowSpan={1} colSpan={1} aria-label="Actions" style={{width: '150px'}}>Actions</th></tr>
                                        {/*end::Table row*/}
                                        </thead>
                                        {/*end::Table head*/}
                                        {/*begin::Table body*/}
                                        <tbody className="text-gray-600 fw-semibold">
                                        {renderReports()}
                                        </tbody>
                                        {/*end::Table body*/}
                                    </table></div>
                                </div>
                                {/*end::Table*/}
                            </div>) : ((<div className="card-body py-4">
                                {/*begin::Table*/}
                                <div id="kt_table_users_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <div className="table-responsive">
                                        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_table_users">
                                        {/*begin::Table head*/}
                                        <thead>
                                        {/*begin::Table row*/}
                                        <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                                            <th className="min-w-125px sorting" tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1} aria-label="User: activate to sort column ascending" style={{width: '270.336px'}}>Banned Users</th>
                                            <th className="min-w-125px sorting" tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1}  style={{width: '70%'}}></th>
                                            <th className="text-end min-w-100px sorting_disabled" rowSpan={1} colSpan={1} aria-label="Actions" style={{width: '150px'}}>Actions</th></tr>
                                        {/*end::Table row*/}
                                        </thead>
                                        {/*end::Table head*/}
                                        {/*begin::Table body*/}
                                        <tbody className="text-gray-600 fw-semibold">
                                        {renderBanned()}
                                        </tbody>
                                        {/*end::Table body*/}
                                    </table></div>
                                </div>
                                {/*end::Table*/}
                            </div>))}
                            {/*end::Card body*/}
                        </div>
                        {/*end::Card*/}
                    </div>
                    {/*end::Content container*/}
                </div>

            </div>) : (<div>
                <div style={{position: 'absolute', left: '50%',  top: '50%', transform: 'translate(-50%, -50%)'}}>
                    <div style={{display: 'flex', flexDirection: 'column', width: '20vw', minWidth: '250px'}}>
                        <span>Admin login</span>
                        <input className={'form-control'} value={username} onChange={(el) => setUsername(el.target.value)} style={{marginTop: '10px'}} placeholder={'Username'}/>
                        <input className={'form-control mt-3'} type={'password'} value={password} onChange={(el) => setPassword(el.target.value)} placeholder={'Password'}/>
                        <button onClick={() => Login()} style={{height: '50px'}} className={'btn btn-primary mt-3'}>Login</button>
                    </div>

                </div>
            </div>)}

        </div>

    }


}
