import React, {useEffect, useState} from "react";
import * as geofire from "geofire-common";
import { doc, setDoc } from "firebase/firestore";
import {db, auth} from "../../../firebase/firebase";
import {useNavigate} from "react-router-dom";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import Geocode from "react-geocode";

Geocode.setApiKey("AIzaSyDKcbs0HWqyy1OyeRfWVcOJ_FJ9fdVVshY");
Geocode.setLocationType("APPROXIMATE");
Geocode.setLanguage("en");

export const Location = ({edit}) => {

    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [country, setCountry] = useState('')
    const [region, setRegion] = useState('')


    useEffect(() => {

        const getGeo = (latitude, longitude) => {
            const userRef = doc(db, "users", auth.currentUser.uid);
            const hash = geofire.geohashForLocation([latitude, longitude]);


            setDoc(userRef, {
                geohash: hash,
                long: longitude,
                lat: latitude
            }, {merge: true}).then((resp) => {
                if (edit) {
                    navigate('/account')
                } else {
                    navigate('/profile')
                }

            }).catch((error) => {
                console.log(error.message)
                alert('An error occurred, please try again')
                setLoading(false)
            });
        }

        // ****** Remember to restrict maps api key

        if (region !== '') {
            setLoading(true)
            //    Here get coordinates
            Geocode.fromAddress(region).then(
                (response) => {
                    const { lat, lng } = response.results[0].geometry.location;
                    getGeo(lat, lng)
                },
                (error) => {
                    alert(error)
                    setLoading(false)
                })
        }

    }, [region])


    return (<div style={edit ? ({maxWidth: '700px', margin: 'auto', marginTop: '40px'}) : ({})} className="fv-row mb-7 fv-plugins-icon-container">
            {/*begin::Label*/}
            <div className="pb-10 pb-lg-15">
                {/*begin::Title*/}
                <h2 className="fw-bold d-flex align-items-center text-dark">Select your city
                    <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" aria-label="Billing is issued based on your selected account type" data-bs-original-title="Billing is issued based on your selected account type" data-kt-initialized={1} /></h2>
                {/*end::Title*/}
                {/*begin::Notice*/}
                <div className="text-muted fw-semibold fs-6">We need this so we can find users near you
                </div>
                {/*end::Notice*/}
            </div>

            <CountryDropdown
                className={'form-control'}
                value={country}
                onChange={(val) => setCountry(val)} />

            <RegionDropdown
                className={'form-control mt-2'}
                country={country}
                value={region}
                onChange={(val) => setRegion(val)} />
                {/*end::Wrapper*/}

    </div>
    )

}
