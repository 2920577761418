import {useDispatch, useSelector} from "react-redux";
import './profile.css'
import {setShown} from "../redux/slices/profile";

export const ProfileComponent = () => {

    const person = useSelector(state => state.profile.person)
    const dispatch = useDispatch()

    const getMarital = (id) => {
        if (id === 'NM') {
            return 'Never married'
        } else if (id === 'W') {
            return 'Widowed'
        } else if (id === 'S') {
            return 'Seperated'
        } else if (id === 'D') {
            return 'Divorced'
        } else {
            return ''
        }
    }

    const renderHobbies = () => {

        return person.interests.map((item) => {
            return <span className={'hobby_item'}>{item}</span>
        })


    }


    return <div>
        <div>
            <button style={{height: '40px', position: 'absolute', zIndex: '999', bottom: '0'}} onClick={() => dispatch(setShown(false))}>Click to go back</button>
        </div>
        <div className={'profile_container'}>

            <div className={'profile_contents'}>
                <img src={person.profile.profileImg}
                     className={'profile_img'}/>

                <text style={{maxWidth: '70%',
                    textAlign: 'center', display: 'block',
                    margin: 'auto'}}><br/>{person.profile.bio}</text>

                <div style={{padding: '20px'}}>
                    <div style={{margin: 'auto', textAlign: 'center'}}>
                        <span>Height: {[person.profile.height]}cm</span>
                        <span style={{marginLeft: '20px'}}>Children: {person.profile.children}</span>
                        <span style={{marginLeft: '20px'}}>Martial status: {getMarital(person.profile.marital_status)}</span>
                    </div>

                    <div style={{padding: '30px'}}>
                        {/*Personalities & hobbies*/}
                        Hobbies & Interests
                       <div style={{display: 'flex', flexWrap: 'wrap'}}>
                           {renderHobbies()}
                       </div>


                    </div>

                </div>



            </div>

        </div>

    </div>

}
