import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        current_user: 'loading',
    },
    reducers: {
        setUser: (state, action) => {
            state.current_user = action.payload
        },

    },
})

export const { setUser } = authSlice.actions

export default authSlice.reducer
