import {
    signInWithEmailAndPassword,
    GoogleAuthProvider,
    signInWithPopup,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    sendPasswordResetEmail,
    fetchSignInMethodsForEmail
} from "@firebase/auth";
import {auth} from "../../firebase/firebase";
import {AppHeader} from "../../components/app-header";
import {useEffect, useState} from "react";
import {Navigate, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";


export const Login = () => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [repeatPass, setRepeatPass] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const [isFb, setFb] = useState(false)

    const [accountType, setAccountType] = useState(undefined)


    useEffect(() => {

        const isFacebookInAppBrowser = () => /FB_IAB/.test(navigator.userAgent) ||
            /FBAN/.test(navigator.userAgent) ||
            /FBAV/.test(navigator.userAgent) ||
            /Instagram/.test(navigator.userAgent) ||
            /Snapchat/.test(navigator.userAgent) ||
            /TikTok/.test(navigator.userAgent)

        setFb(isFacebookInAppBrowser())


    }, [])


    const provider = new GoogleAuthProvider();

    const user = useSelector(state => state.auth.current_user)

    useEffect(() => {

        if (user !== null && user !== 'loading') {
            navigate('/')
        }

    }, [user])


    const validateEmail = (email) => {
        setError('')
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const googleSignIn = () => {
        setError('')
        signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                navigate('/')
                // ...
            }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            setError('Google sign in - ' + errorCode.split('/')[1])

            // ...
        });
    }


    const login = () => {

        if (loading) {
            return;
        }

        setError('')
        setLoading(true)

        if (!validateEmail(email)) {
            setError('Email is incorrect')
            setLoading(false)
            return
        }

        if (accountType === undefined) {
            fetchSignInMethodsForEmail(auth, email).then((accounts) => {
                setLoading(false)
                setError('')
                if (accounts.length === 0) {
                    setAccountType('signup')
                } else if (accounts.length === 1) {
                    if (accounts[0] === 'google.com') {
                        setAccountType('google')
                    } else {
                        setAccountType('login')
                    }

                } else {
                    setAccountType('login')
                }
            })

            return;
        }


        if (accountType === 'signup' || accountType === 'login') {
            if (password.length < 6) {
                setError('Password length is too short')
                setLoading(false)
                return;
            }
            if (accountType === 'signup') {
                if (password !== repeatPass) {
                    setError('Passwords do not match')
                    setLoading(false)
                    return;
                }
            }
        }

        if (accountType === 'signup') {
            createUserWithEmailAndPassword(auth, email, password)
                .then(response => {
                        const user = response.user
                        if (user) {
                            sendEmailVerification(user).then((resp) => {
                                navigate('/')
                            })
                        } else {
                            alert('Unknown error occurred, please try again')
                            setLoading(false)
                        }
                    }
                ).catch((error) => {
                if (error.code === 'auth/email-already-in-use') {
                    setError('An account already exists with this email')
                    setLoading(false)
                } else {
                    setError(error.code.split('/')[1])
                    setLoading(false)
                }
            })

        } else if (accountType === 'login') {
            signInWithEmailAndPassword(auth, email, password).then((user) => {
                navigate('/')
            }).catch((error) => {
                if (error.code === 'auth/user-not-found') {
                    setError('Account does not exist')
                    setLoading(false)
                } else if (error.code === 'auth/wrong-password') {
                    setError('Incorrect Password')
                    setLoading(false)
                } else {
                    setError('Something went wrong, please try again')
                    setLoading(false)
                }

            })
        }

    }

    const forgotPassword = () => {

        const email = window.prompt('Please enter the email address of your account')

        if (email === '') {
            return
        }

        sendPasswordResetEmail(auth, email).then((success) => {
            alert('An email has been sent with instructions to reset your password')
        }).catch((error) => {
            if (error.code === 'auth/invalid-email') {
                alert('Please enter a valid email address')
            } else if (error.code === 'auth/user-not-found') {
                alert('No account exists with this email address')
            } else {
                alert('Failed to reset password')
            }

        })

    }

    const renderNext = () => {

        if (accountType === undefined) {
            return <button style={{height: '45px', marginTop: '5px'}} onClick={() => login()} className="btn btn-primary">

                {loading ?
                    <span className="spinner-border spinner-border-m align-middle ms-2 " /> :
                    <span className="indicator-label">Continue</span>}

            </button>
        } else if (accountType === 'login') {
            return <button style={{height: '45px', marginTop: '5px'}} onClick={() => login()} className="btn btn-primary">

                {loading ?
                    <span className="spinner-border spinner-border-m align-middle ms-2 " /> :
                    <span className="indicator-label">Sign In</span>}

            </button>
        } else if (accountType === 'signup') {
            return <button style={{height: '45px', marginTop: '5px'}} onClick={() => login()} className="btn btn-primary">

                {loading ?
                    <span className="spinner-border spinner-border-m align-middle ms-2 " /> :
                    <span className="indicator-label">Create new account</span>}

            </button>
        } else {
            return
        }

    }


    return <div className="d-flex flex-column flex-root" id="kt_app_root">
        <AppHeader/>
        <div className="d-flex flex-column flex-lg-row flex-column-fluid" style={{alignSelf: 'center'}}>
            {/*begin::Aside*/}

            {/*begin::Aside*/}
            {/*begin::Body*/}
            <div className="d-flex flex-column flex-lg-row flex-column-fluid p-12" >
                {/*begin::Wrapper*/}
                <div className="bg-body d-flex flex-center rounded-4 w-md-600px p-10" style={{width: '90vw'}}>
                    {/*begin::Content*/}
                    <div style={{width: 'inherit'}}>
                        {/*begin::Form*/}
                        <div className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
                            {/*begin::Heading*/}
                            <div className="text-center mb-11">
                                {/*begin::Title*/}
                                <h1 className="text-dark fw-bolder mb-3">Welcome</h1>
                                {/*end::Title*/}
                                {/*begin::Subtitle*/}
                                <div className="text-gray-500 fw-semibold fs-6">Guursamo Marriage App</div>
                                {/*end::Subtitle=*/}
                            </div>
                            {/*begin::Heading*/}
                            {/*begin::Login options*/}

                            {isFb ? (null) : (<div className="row g-3 mb-9">

                                <div className="col-md-6" style={{margin: 'auto'}}>

                                    <a onClick={() => googleSignIn()} className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
                                        <img alt="Logo" style={{width: '17px', height: '17px'}} src="/assets/icons/google.png" className=" me-3" />Sign in with Google</a>

                                </div>
                                <div className="separator separator-content my-14">
                                    <span className="w-125px text-gray-500 fw-semibold fs-7">Or continue with email</span>
                                </div>
                            </div>)}


                            <div className="fv-row mb-8 fv-plugins-icon-container">

                                <input type="text" disabled={accountType === undefined ? (false) : (true)} onChange={(el) => setEmail(el.target.value)} placeholder="Email" name="email" autoComplete="off" className="form-control bg-transparent" />

                                <div className="fv-plugins-message-container invalid-feedback" />
                            </div>

                            {accountType === undefined || accountType === 'google' ? (null) : (<div className="fv-row mb-3 fv-plugins-icon-container">

                                <input type="password" onChange={(el) => setPassword(el.target.value)} placeholder="Password" name="password" autoComplete="off" className="form-control bg-transparent" />

                                <div className="fv-plugins-message-container invalid-feedback" />
                            </div>)}

                            {accountType === 'signup' ? (<div className="fv-row mb-3 fv-plugins-icon-container">

                                <input type="password" onChange={(el) => setRepeatPass(el.target.value)} placeholder="Confirm Password" name="password" autoComplete="off" className="form-control bg-transparent" />

                                <div className="fv-plugins-message-container invalid-feedback" />

                            </div>) : (null) }

                            {accountType === 'login' ? (<div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                                <div />
                                <a onClick={() => forgotPassword()} className="link-primary">Forgot Password ?</a>
                            </div>): (null)}


                            {accountType === 'google' ? (<div>
                                <span>{email} - please sign in with your Google account.</span>
                                {isFb ? (<div><h6>Please note Google Login is not available on this browser.</h6> <br/><br/>
                                <h4>Please use Chrome / Safari / Edge or your default web browser</h4>
                                </div>) : (null)}
                            </div>) : (null)}

                            <div className="d-grid mb-10">
                                {renderNext()}
                            </div>

                            {accountType !== undefined ? (<button style={{backgroundColor: '#f1f1f1', color: 'black'}}
                                onClick={() => setAccountType(undefined)}>Change email</button>) : (null)}

                            {error === '' ? '' : <span style={{color: 'red', textAlign: 'center',
                                display: 'block', margin: 'auto', fontSize: '16px', marginTop: '20px'}}>{error}</span>}

                        </div>

                    </div>

                </div>
                {/*end::Wrapper*/}
            </div>
            {/*end::Body*/}
        </div>

    </div>




}
